import React, { useState } from 'react';
import { Breadcrumb, Button, Dropdown, Image, Space, Table, Tag } from "antd";
import Leader from "./testleaders.jpeg";
import { DownOutlined } from "@ant-design/icons";

export default function PageWiseDedicated() {

    const items = [
        {
            key: '1',
            label: 'ICT',
        },
        {
            key: '2',
            label: 'Bouwsector',
        },
        {
            key: '3',
            label: 'N/A',
        },
        {
            key: '4',
            label: 'Alle categorieën',
        },
    ];

    const [selectedUser, setSelectedUser] = useState("");

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text) => <p className="whitespace-nowrap">{text}</p>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            fixed: 'left',
        },
        {
            title: 'Job',
            dataIndex: 'job',
            key: 'job',
            fixed: 'left',
        },
        {
            title: 'Top 3 categorieën',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags) => {
                return tags.sort((a, b) => b.score - a.score).map((tag) => {
                    return tag.score > 75 ? <Tag color="#29bf00">{tag.label} ({tag.score})</Tag> : tag.score < 50 ? <Tag color="#ffa600">{tag.label} ({tag.score})</Tag> : <Tag color="#ff5500">{tag.label} ({tag.score})</Tag>
                });
            },
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => (
                <div className="flex flex-col gap-3">
                    <Button onClick={() => { setSelectedUser(record.key) }}>Hide {record.name}</Button>
                </div>
            ),
        },
    ];

    const [selectedJob, setSelectedJob] = useState("");

    const labels = ["Match", "Attractiveness", "Salary", "Information", "Culture", "Speed"];
    const jobs = ["Developer Coördinator", "Developer Tester", "Analysator Coördinator", "Manager Coördinator", "ICT Coördinator", "Developer ICT", "Manager ICT", "CEO Coördinator", "Insights Manager"]

    const data = [
        {
            key: '1',
            name: 'Johnbro Brown',
            email: "Johnbro@Brown.com",
            jobKey: '1',
            job: jobs[Math.floor(Math.random() * jobs.length)],
            tags: [{ score: Math.floor(Math.random() * (101 - 50)) + 50, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 75)) + 75, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 10)) + 10, label: labels[Math.floor(Math.random() * labels.length)] }]
        },
        {
            key: '2',
            name: 'Jimeey Green',
            email: "Jimeey@Green.com",
            jobKey: '1',
            job: jobs[Math.floor(Math.random() * jobs.length)],
            tags: [{ score: Math.floor(Math.random() * (101 - 50)) + 50, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 75)) + 75, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 10)) + 10, label: labels[Math.floor(Math.random() * labels.length)] }]
        },
        {
            key: '3',
            name: 'Joeder Black',
            email: "Joeder@Black.com",
            jobKey: '2',
            job: jobs[Math.floor(Math.random() * jobs.length)],
            tags: [{ score: Math.floor(Math.random() * (101 - 50)) + 50, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 75)) + 75, label: labels[Math.floor(Math.random() * labels.length)] },
            { score: Math.floor(Math.random() * (101 - 10)) + 10, label: labels[Math.floor(Math.random() * labels.length)] }]
        },
    ];

    return (
        <div>
            <div className="p-5 h-[20vh] bg-slate-200">HEADER</div>
            <div className="m-5">
                <Breadcrumb items={[
                    {
                        title: 'Home',
                    },
                    {
                        title: "Lijst met gemotiveerde kandidaten",
                    }
                ]}>
                </Breadcrumb>
                <div className="flex gap-5 justify-between">
                    <div>
                        <p className="font-semibold text-2xl mt-5">List of motivated candidates<span className="ml-2">({data.length - data.filter(x => { return x.job !== selectedJob && x.key !== selectedUser; }).length} hidden)</span></p>
                        <p className="mt-2 text-gray-400">Enter a job or period and get access tot those candidates that are highly motivated to reapply.</p>
                        <div className="mt-5 flex gap-2 flex-wrap">
                            <Dropdown className="mr-4 flex-grow" menu={{
                                items,
                                selectable: true,
                                defaultSelectedKeys: ['3'],
                            }}>
                                <Button>
                                    <Space>
                                        Marketing
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                            <Dropdown className="mr-4 flex-grow" menu={{
                                items,
                                selectable: true,
                                defaultSelectedKeys: ['3'],
                            }}>
                                <Button className="flex-grow">
                                    <Space>
                                        Alle maanden
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                            <Button type="primary">Bekijk lijst</Button>
                        </div>
                        <div className="my-3 flex gap-1 flex-wrap">
                            {jobs.map((job) => { return <Tagging name={job} selectedJob={selectedJob} setSelectedJob={setSelectedJob} /> })}
                        </div>
                    </div>
                    <div className="mt-12 relative">
                        <div className="flex">
                            <div className="-mt-6 w-1/3 text-left text-gray-400">Attractiveness</div>
                            <div className="-mt-11 w-1/3 text-center text-yellow-500">Information</div>
                            <div className="-mt-1 w-1/3 text-right text-amber-700">Match</div>
                        </div>
                        <Image className="w-full h-auto" rootClassName="w-full h-auto" preview={false} src={Leader}></Image>
                    </div>
                </div>
                <Table scroll={{
                    x: 'max-content',
                }} pagination={false} columns={columns} dataSource={data.filter(x => { return x.job !== selectedJob && x.key !== selectedUser; })} />
            </div>
            <div className="p-5 h-[50vh] flex-grow bg-slate-800">FOOTER</div>
        </div>
    );
}

export function Tagging(props) {
    return (
        <Tag className="hover:cursor-pointer mr-0" closeIcon onClose={(event) => { event.preventDefault(); props.setSelectedJob(props.selectedJob === props.name ? "" : props.name); }} color={`${props.selectedJob === props.name ? "#f50" : "#87d068"}`} onClick={() => { props.setSelectedJob(props.selectedJob === props.name ? "" : props.name); }}>{props.name}</Tag >
    );
}