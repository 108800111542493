import React, { useState } from 'react';
import { Alert, Badge, Button, Checkbox, Image, Modal, Select, Tag } from "antd";
import Lego from "./lego.png";
import Process from "./Process.png";
import { CustomerServiceTwoTone, DashboardTwoTone, FacebookFilled, InfoCircleOutlined, LikeTwoTone, LinkedinFilled, LinkOutlined, MailOutlined, PhoneOutlined, PushpinOutlined, RightOutlined, TikTokFilled, UploadOutlined } from "@ant-design/icons";

export default function PageWiseFunctions() {
    return (
        <div>
            <div className="p-5 h-[20vh] bg-slate-200">HEADER</div>
            <div className="p-5 bg-[#01aa98] flex justify-between">
                <Select
                    placeholder="Sorteren op"
                    className="w-min"
                    popupMatchSelectWidth={false}
                    options={[
                        {
                            value: 'new',
                            label: 'Nieuwste eerst',
                        },
                        {
                            value: 'old',
                            label: 'Oudste eerst',
                        }
                    ]}
                />
                <div>
                    <Checkbox>Toon enkel actieve jobs</Checkbox>
                </div>
            </div>
            <Alert className="m-5" message={<><p>Je hebt nog geen CV ingevuld in je profiel. <a className="!underline" href="#profiel">Uploaden</a></p></>} type="warning" showIcon closable />
            <div className="m-5 max-w-[1150px] mx-auto">
                <Bedrijf jobs={[<Job open />, <Job />]} />
                <Bedrijf jobs={[<Job afgewezen />]} />
                <Bedrijf jobs={[<Job afgewezen />, <Job open afgewezen />, <Job />]} />
            </div>
            <div className="p-5 h-[50vh] flex-grow bg-slate-800">FOOTER</div>
        </div>
    );
}

function getRandomDateLastYear() {
    const now = new Date();
    const start = new Date(now.getFullYear() - 1, 0, 1); // January 1st
    const end = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999); // December 31st
    const randomTimestamp = Math.random() * (end - start) + start.getTime();
    return new Date(randomTimestamp);
}

function Job({ open, afgewezen }) {
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const handleOk2 = () => {
        setIsModalOpen2(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const showModal3 = () => {
        setIsModalOpen3(true);
    };
    const handleOk3 = () => {
        setIsModalOpen3(false);
    };
    const handleCancel3 = () => {
        setIsModalOpen3(false);
    };

    return (
        <div className="mx-5 mt-5 mb-5 rounded-md border-2 border-[#5f5f5f33] shadow-md">
            <div className="px-5 py-3">
                <p><LinkOutlined className="text-3xl hover:invert cursor-pointer" /> ICT Development Manager ({getRandomDateLastYear().toLocaleDateString()}) {open ? <Tag color="#00ff00">Actief</Tag> : <Tag color="#f50">Gesloten</Tag>}{afgewezen ? <Tag color="#f50">Afgewezen</Tag> : <></>}</p>
            </div>
            <div className="px-5">
                <p>
                    <span className="text-sm">Recruiter:</span>
                    <Button type="text" className="underline px-1 text-sm" onClick={() => { showModal2(true) }}>Maarten van Mossel</Button>
                    <span className="text-lg">
                        <LikeTwoTone className="transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#0000ff"} />
                        <CustomerServiceTwoTone className="transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#ff0000"} />
                        <DashboardTwoTone className="transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#00ff00"} />
                    </span>
                </p>
                <Modal cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }} title={<div className="flex">
                    <Image preview={false} src={Lego} width={32} height={32} />
                    <p className="ml-2 my-auto">Maarten van Mossel</p>
                </div>
                } open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                    <p>Telefoon: <a href="https://vvoids.com" className="underline">+32 498 03 87 13</a></p>
                    <p>Email: <a href="https://vvoids.com" className="underline">vince@vvoids.com</a></p>
                    <div className="mt-5 flex gap-5">
                        <Badge count={5}><LikeTwoTone className="text-4xl transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#0000ff"} /></Badge>
                        <Badge count={12}><CustomerServiceTwoTone className="text-4xl transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#ff0000"} /></Badge>
                        <Badge count={1}><DashboardTwoTone className="text-4xl transition-transform transform hover:scale-150 cursor-pointer px-1" twoToneColor={"#00ff00"} /></Badge>
                    </div>
                </Modal>
            </div>
            <Alert className="mx-5 mt-2" message={<><p>Je hebt nog geen motivatiebrief ingevuld op deze job. <a className="!underline" href="#profiel">Uploaden</a></p></>} type="warning" showIcon closable />
            <div className="px-5 my-4 flex justify-between">
                <Button type="primary" icon={<UploadOutlined />}>Motivatiebrief.docx</Button>
                <Button type="text" className="underline px-1 text-sm" onClick={() => { showModal3(true) }}>Gelijkaardige jobs bij dit bedrijf<Badge count={5} /></Button>
                <Modal cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }} title={<div className="flex">
                    <Image preview={false} src={Lego} width={32} height={32} />
                    <p className="ml-2 my-auto">Lego Inc.<Badge className="ml-2" count={5} /></p>
                </div>
                } open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3}>
                    <p><a href="https://vvoids.com" className="underline">Azure Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                    <p><a href="https://vvoids.com" className="underline">DevOps Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                    <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                    <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                    <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                </Modal>
            </div>
            <div className="w-full relative px-5 pb-5">
                <Button type="text" className="p-5 w-full bg-[#01aa98]">
                    Volg je sollicitatie process...
                </Button>
            </div>
        </div>
    );
}

function Bedrijf({ jobs }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const showModal4 = () => {
        setIsModalOpen4(true);
    };
    const handleOk4 = () => {
        setIsModalOpen4(false);
    };
    const handleCancel4 = () => {
        setIsModalOpen4(false);
    };

    const [isJobsOpen, setIsJobsOpen] = useState(false);

    return (
        <div className="rounded-lg border-solid border-2 border-[#5f5f5f33] shadow-md w-full mt-14 pb-2">
            <div className="flex relative">
                <Image rootClassName="absolute" preview={false} src={Lego} width={100} height={100} className="-mt-10 ml-5" />
                <div className="flex flex-col">
                    <Button type="text" className="ml-32 w-min mt-3 font-semibold text-lg underline py-0 px-1" onClick={() => { showModal(true) }}>Lego Inc.<InfoCircleOutlined /></Button>
                    <Button type="text" className="ml-32 underline px-1 text-sm" onClick={() => { showModal4(true) }}>Alle jobs bij dit bedrijf<Badge count={23} /></Button>
                    <Modal cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }} title={<div className="flex">
                        <Image preview={false} src={Lego} width={32} height={32} />
                        <p className="ml-2 my-auto">Lego Inc.<Badge className="ml-2" count={23} /></p>
                    </div>
                    } open={isModalOpen4} onOk={handleOk4} onCancel={handleCancel4}>
                        <p><a href="https://vvoids.com" className="underline">Azure Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Azure Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Azure Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Azure Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Manager ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">DevOps Engineer ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">Assistant Development ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                        <p><a href="https://vvoids.com" className="underline">IT Support ({getRandomDateLastYear().toLocaleDateString()})<RightOutlined className="ml-1" /></a></p>
                    </Modal>
                </div>
                <div className="text-xs gap-2 mt-5 mx-5 hidden [@media(min-width:650px)]:flex">
                    <p className="hidden [@media(min-width:1100px)]:block"><PushpinOutlined /><a href="https://vvoids.com" className="underline ml-1">Brandstraat 68, 2850 Boom</a></p>
                    <p className="hidden [@media(min-width:900px)]:block"><PhoneOutlined /><a href="https://vvoids.com" className="underline ml-1">+32 498 03 87 13</a></p>
                    <p className="hidden [@media(min-width:900px)]:block"><MailOutlined /><a href="https://vvoids.com" className="underline ml-1">vince@vvoids.com</a></p>
                    <p><a href="https://vvoids.com" className="underline">Website</a></p>
                    <p><a href="https://vvoids.com" className="underline">Jobsite</a></p>
                </div>
                <span className="text-2xl mt-3 ml-2 cursor-pointer hidden [@media(min-width:1200px)]:block"><FacebookFilled className="hover:invert" /><TikTokFilled className="mx-1 hover:invert" /><LinkedinFilled className="hover:invert" /></span>
                <div className="absolute right-0 top-1">
                    <Checkbox onChange={(event) => { setIsJobsOpen(event.target.checked) }}>Verberg jobs</Checkbox>
                </div>
                <Modal cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }} title={<div className="flex">
                    <Image preview={false} src={Lego} width={32} height={32} />
                    <p className="ml-2 my-auto">Lego Inc.</p>
                </div>
                } open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p>Adres: <a href="https://vvoids.com" className="underline">Brandstraat 68, 2850 Boom</a></p>
                    <p>Telefoon: <a href="https://vvoids.com" className="underline">+32 498 03 87 13</a></p>
                    <p>Email: <a href="https://vvoids.com" className="underline">vince@vvoids.com</a></p>
                    <p>Website: <a href="https://vvoids.com" className="underline">https://vvoids.com</a></p>
                    <p>Jobsite: <a href="https://vvoids.com" className="underline">https://vvoids.com/jobs</a></p>
                    <div className="mt-3">
                        <Image preview={false} src={Process} />
                    </div>
                </Modal>
            </div>
            <Alert className="mx-5 mt-3" message={<><p>Dit bedrijf behandeld normaal je sollicitatie binnen 2 dagen.</p></>} type="warning" showIcon closable />
            {isJobsOpen ? <></> : jobs.map(x => { return x; })}
        </div>
    );
}