import { BrowserRouter, Routes, Route } from "react-router-dom";

import PageRedirect from "./pages/vvoids/redirect";
import Main from "./pages/vvoids/main";
import PagePrivacy from "./pages/vvoids/privacy";
import PageTerms from "./pages/vvoids/terms";
import Photobooth from "./pages/sanctamaria/photobooth";
import PageWiseDedicated from "./pages/wisededicated";
import PageWiseFunctions from "./pages/wisefunctions";

export default function Index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"sanctamaria"} element={<PageRedirect
                    redirect={"photobooth"}
                />} />
                <Route path={"sanctamaria/photobooth"} element={<Photobooth
                />} />
                <Route path={"privacy"} element={<PagePrivacy
                />} />
                <Route path={"terms"} element={<PageTerms
                />} />
                <Route path={"test/dedicated"} element={<PageWiseDedicated
                />} />
                <Route path={"test/wisefunctions"} element={<PageWiseFunctions
                />} />
                <Route path={"/"} element={<Main
                />} />
                <Route path={"*"} element={<PageRedirect
                    redirect={"/"}
                />} />
            </Routes>
        </BrowserRouter>
    );
}